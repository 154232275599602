import { render, staticRenderFns } from "./LearningSpacesList.vue?vue&type=template&id=256fbfc7&scoped=true"
import script from "./LearningSpacesList.vue?vue&type=script&lang=js"
export * from "./LearningSpacesList.vue?vue&type=script&lang=js"
import style0 from "./LearningSpacesList.vue?vue&type=style&index=0&id=256fbfc7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256fbfc7",
  null
  
)

export default component.exports