<template>
  <div class="learning-spaces__card">
    <div
      class="learning-spaces__card--div"
      v-for="(s, i) in spaces"
      :key="i"
      @click="onCardClick(s)"
    >
      <img :src="s.logo" :alt="s.name" />
    </div>
  </div>
</template>

<script>
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
export default {
  name: "SpacesCard",
  mixins: [redirectToLearningSpace],
  props: {
    spaces: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onCardClick(s) {
      this.navigateToLearningSpace(s.org_short_name);
    }
  }
};
</script>

<style lang="scss" scoped>
.learning-spaces__card {
  display: flex;
  .learning-spaces__card--div {
    padding: 8px 10px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
    border-radius: 8px;
    margin-left: 24px;
    height: 71px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    img {
      height: 100%;
    }
    &:hover {
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
      transform: scale(1.04);
    }
  }
}
[dir="rtl"] {
  .learning-spaces__card {
    .learning-spaces__card--div {
      margin: 0 27px 0 0;
      &:first-child {
        margin-right: 0;
      }
    }
  }
}
</style>
