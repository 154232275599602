<template>
  <div class="cd-main__spaces" v-if="spacesList.length > 0">
    <h5 class="cd-main__heading">
      {{ $t("course_discovery.top_learning_spaces") }}
    </h5>
    <SpacesCard :spaces="spacesList" />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SpacesCard from "./SpacesCard.vue";
export default {
  name: "LearningSpacesList",
  components: {
    SpacesCard
  },
  data() {
    return {
      spacesList: []
    };
  },
  computed: {
    ...mapGetters(["allConfig"])
  },
  created() {
    this.getLearningSpacesList();
  },
  methods: {
    getLearningSpacesList() {
      axios
        .get(`${this.allConfig.baseURL}/spaces/live/`, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`
          }
        })
        .then(res => {
          this.spacesList = res.data;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.cd-main__spaces {
  margin-bottom: 24px;
  .cd-main__heading {
    margin-bottom: 16px;
    @include subtitle-regular;
  }
}
[dir="rtl"] {
  .cd-main__spaces {
    text-align: right;
  }
}
</style>
